<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('users.moveCustomerToStaff.title')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <v-flex
                v-if="points > 0"
                xs12
                sm12
                md12>
                <p> {{ $t('users.customerRefundPoint.customerInfo') }}
                  <span class="font-weight-bold"> {{ fullName }} </span>
                  <span> ( {{ email }} )</span>
                  <span> {{ $t('users.customerRefundPoint.customerCurrentPoint') }} </span>
                  <span class="font-weight-bold"> {{ points }} {{ $t('users.points') }}</span>
                </p>
                <p> {{ $t('users.moveCustomerToStaff.notiUserHaveNotRefundedPoints') }}</p>
              </v-flex>
              <v-flex
                v-else
                xs12
                sm12
                md12>
                <p> {{ $t('users.moveCustomerToStaff.notiConfirm', { '0': fullName }) }} </p>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          v-if="points === 0 && isSuperAdmin()"
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import { mapActions, mapGetters } from 'vuex'
// import NotificationAction from 'enum/notificationAction'
import RoleType from 'enum/roleType'
export default {
  components: {},
  data () {
    return {
      valid: false,
      isShowModal: false,
      userId: null,
      fullName: null,
      phone: null,
      menu: false,
      currentPage: 1,
      email: null,
      points: null,
      isLoading: false,
      currency: null,
      currencyFormat: null,
      exchangeRate: null,
      confirmModalTitle: '',
      code: null
    }
  },
  computed: {
    ...mapGetters(['GET_CURRENT_ACCOUNT'])
  },
  created () {},
  methods: {
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    isGreaterThanZero (value) {
      value = functionUtils.formatInteger(value)
      if (value > 0) {
        return true
      }
      return this.$t('users.valueMustBeGreaterThanZero')
    },
    onShowModal: function (userData, currentPage) {
      this.currentPage = currentPage
      this.userId = userData && userData.id
      this.fullName = userData && userData.fullname !== undefined ? userData.fullname : userData.name
      this.phone = userData && userData.phone
      this.email = userData && userData.email
      this.code = userData && userData.code
      this.isShowModal = true
      this.points = userData.points
      this.currencyFormat = null
      this.currency = null
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      let data = {
        id: this.userId,
        groupId: RoleType.MODERATOR
      }
      this.UPDATE_USER_GROUP(data).then(
        function () {
          this.isShowModal = false
          this.$emit('moveToStaff', true)
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      )
        .catch(
          function (error) {
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                'message': this.$t(`${errorText}`),
                styletype: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                'message': this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
    },
    ...mapActions(['UPDATE_USER_GROUP', 'ON_SHOW_TOAST', 'PUSH_NOTIFICATION'])
  }

}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
