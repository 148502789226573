<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      v-if="hasAction('view_user') || isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="userSearch.searchText"
                    :label="$t('common.search')"
                    clearable
                    @keydown.enter="onSearch($event)"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch($event)"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('users.userList')"
          color="green"
          flat
          full-width
        >
          <template>
            <v-btn
              v-if="hasAction('create_user') || isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddUser(null)"
            >
              {{ $t('common.add') }}
            </v-btn>
          </template>
          <v-data-table
            v-if="hasAction('view_user') || isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
            :no-data-text="$t('common.noDataAvailable')"
            :headers="userHeaders"
            :items="userList"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.stt }}</td>
              <td
                class="cursor-pointer"
                @mouseover="onHoverEmail(item)"
                @mouseleave="onLeaveEmail(item)"
              >
                <span
                  class="pb-1"
                  style="border-bottom: 1px dashed #5cb860;">{{ item.fullname }}</span>
                <div
                  v-show="item.isShowTool"
                  class="mt-1"
                  style="display: flex;">
                  <span
                    class="text-success hover-detail-text"
                    @click="onShowModalViewDetailUser(item)">{{ $t('users.viewDetail') }}</span>
                  <span
                    class="mx-2">|</span>
                  <span
                    class="hover-delete-text"
                    style="color: blue;"
                    @click="onShowTransactionHistory(item.id)">{{ $t('common.history') }}</span>
                  <span
                    v-if="(hasAction('delete_user') && item.id != GET_CURRENT_ACCOUNT.id) || isAdmin() || isSuperAdmin() || isAccountant()"
                    class="mx-2">|</span>
                  <span
                    v-if="(hasAction('delete_user') && item.id != GET_CURRENT_ACCOUNT.id) || isAdmin() || isSuperAdmin() || isAccountant()"
                    class="hover-delete-text"
                    style="color: red;"
                    @click="onShowModalDeleteUser(item.id)">{{ $t('common.delete') }}</span>
                </div>
              </td>
              <td>{{ item.email }}</td>
              <td>{{ item.phone }}</td>
              <td class="font-weight-bold">{{ item.points }}</td>
              <!-- <td>{{ $t(getGender(item.gender)) }}</td>
              <td>{{ item.dob }}</td>
              <td>{{ item.dateCreated }}</td> -->
              <td>
                <v-tooltip
                  v-if="!item.isActive"
                  top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="error"
                      dark
                      v-on="on">mdi-close-outline</v-icon>
                  </template>
                  <span>{{ $t('users.state.in_active') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-else-if="item.status === customerStatus.LOCK"
                  top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="error"
                      dark
                      v-on="on">mdi-lock</v-icon>
                  </template>
                  <span>{{ $t('users.status.lock') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-else
                  top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="success"
                      dark
                      v-on="on">mdi-check-outline</v-icon>
                  </template>
                  <span>{{ $t('users.state.active') }}</span>
                </v-tooltip>
              </td>
              <td class="text-xs-center">
                <v-menu
                  offset-y
                  transition="slide-y-transition"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="indigo"
                      dark
                      fab
                      small
                      v-on="on">
                      <v-icon>mdi-wrench</v-icon>
                    </v-btn>
                  </template>
                  <v-tooltip
                    v-if="isAccountant()"
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :loading="item.isLoading"
                        :disabled="item.status === customerStatus.LOCK"
                        class="mx-2"
                        fab
                        small
                        color="success"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="onShowModalChargePoints(item)">
                        <v-icon dark>mdi-plus-thick</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('users.addPoints') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="isAccountant()"
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :loading="item.isLoading"
                        :disabled="item.status === customerStatus.LOCK || item.points === 0"
                        class="mx-2"
                        fab
                        small
                        color="teal"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="showModalRefundPoints(item)">
                        <v-icon dark>mdi-credit-card-refund</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('users.refundPoints') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="(isAccountant() || isAdmin() || isSuperAdmin()) && item.status === customerStatus.UNLOCK"
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :loading="item.isLoading"
                        class="mx-2"
                        fab
                        small
                        color="error"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="showModalUpdateCustomer(item.id, customerStatus.LOCK)">
                        <v-icon dark>mdi-lock</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('prepaidCard.lockCard') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="(isAccountant() || isAdmin() || isSuperAdmin()) && item.status === customerStatus.LOCK"
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :loading="item.isLoading"
                        class="mx-2"
                        fab
                        small
                        color="teal"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="showModalUpdateCustomer(item.id, customerStatus.UNLOCK)">
                        <v-icon dark>mdi-lock-open-variant</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('prepaidCard.unlockCard') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="isSuperAdmin()"
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :loading="item.isLoading"
                        class="mx-2"
                        fab
                        small
                        color="info"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="onShowMoveToStaff(item)">
                        <v-icon dark>mdi-account-convert</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('users.moveCustomerToStaff.title') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="isAdmin() || isSuperAdmin() || isAccountant() || isModerator()"
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :loading="item.isLoading"
                        class="mx-2"
                        fab
                        small
                        color="success"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="onShowModalResetPassword(item)">
                        <v-icon dark>mdi-lock-reset</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('common.resetPassword') }}</span>
                  </v-tooltip>
                </v-menu>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      :total="userPaginate.totalPage"
      :current-page="userPaginate.currentPage"
      :row-per-page="userPaginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <addUserModal
      ref="modalAddUser"
      @getApiUserList="getUserList()"/>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <charge-points-modal
      ref="ChargePointsModal"
      @updatePoint="getUserListAfterChargePoints"/>
    <refundPointsModal
      ref="refundPointsModal"
      @updatePoint="getUserListAfterChargePoints"/>
    <!-- confirm delete user -->
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="modalConfirmTypeSelected == modalConfirmType.DELETE_USER ? onDeleteUser() : onResetPassword()"
    />
    <confirm-modal
      ref="confirmLockCustomerModal"
      :title="confirmLockCustomerModalTitle"
      @onConfirm="updateCustomerStatus()"
    />
    <!-- transaction history -->
    <transactionHistoryListForUser
      ref="transactionHistoryListForUser"/>
    <!-- <userDetailModal ref="userDetailModal" /> -->
    <loadingBar :is-loading="isLoading" />
    <!--  -->
    <moveCustomerToStaff
      ref="moveCustomerToStaff"
      @moveToStaff="getUserListAfterChargePoints"/>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import GenderType from 'enum/genderType'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import ToastType from 'enum/toastType'
import AddUserModal from 'Components/AddUserModal'
import Avatar from 'vue-avatar'
import ConfirmModal from 'Components/ConfirmModal'
import stringUtils from 'utils/stringUtils'
import RoleType from 'enum/roleType'
import ChargePointsModal from './ChargePointsModal'
import refundPointsModal from './RefundPointsModal'
import sysGroupEnum from 'enum/sysGroupEnum'
import prepaidCardStatus from 'enum/prepaidCardStatus'
import transactionHistoryListForUser from '../History/TransactionHistoryListForUser'
import moveCustomerToStaff from './MoveCustomerToStaff'
export default {
  components: {
    addUserModal: AddUserModal,
    Avatar,
    ConfirmModal,
    ChargePointsModal,
    refundPointsModal,
    transactionHistoryListForUser,
    moveCustomerToStaff
  },
  data: () => ({
    userHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      // {
      //   sortable: false,
      //   text: 'common.logo',
      //   value: 'avatar'
      // },
      {
        sortable: false,
        text: 'users.fullName',
        value: 'fullname'
      },
      {
        sortable: false,
        text: 'user.email',
        value: 'email'
      },
      {
        sortable: false,
        text: 'users.phone',
        value: 'phone'
      },
      {
        sortable: false,
        text: 'users.points',
        value: 'points'
      },
      {
        sortable: false,
        text: 'common.state',
        value: 'isActive'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    userList: [],
    userPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    userSelected: {
      id: null
    },
    rolesSelected: [],
    isUpdate: true,
    dobDefault: null,
    menu: false,
    isShowUserDetail: true,
    confirmModalTitle: '',
    selected: [],
    userSearch: {
      searchText: null
    },
    gender: stringUtils.GenderList,
    genderList: null,
    isLoading: false,
    isActive: false,
    modalConfirmType: {
      DELETE_USER: 1,
      FORGOT_PASSWORD_USER: 2
    },
    modalConfirmTypeSelected: null,
    userForgotPassSelected: null,
    customerStatus: prepaidCardStatus,
    customerSelect: {
      id: null,
      status: null
    },
    confirmLockCustomerModalTitle: ''
  }),
  computed: {
    ...mapGetters(['GET_SNACK_BAR', 'USER_LIST_DATA', 'GET_CURRENT_ACCOUNT'])
  },
  watch: {
    USER_LIST_DATA () {
      this.userPaginate.currentPage = this.USER_LIST_DATA.paginate.currentPage
      this.userPaginate.totalPage = this.USER_LIST_DATA.paginate.totalPage
      this.userPaginate.rowPerPage = this.USER_LIST_DATA.paginate.perPage
      let userList = this.USER_LIST_DATA.results
      this.userList = []
      for (let i = 0, size = userList.length; i < size; i++) {
        let userObj = {
          stt: i + 1 + (this.USER_LIST_DATA.paginate.currentPage - 1) * this.USER_LIST_DATA.paginate.pageSize,
          // stt: i + 1,
          id: userList[i].id,
          username: userList[i].username,
          fullname: userList[i].last_name + ' ' + userList[i].first_name,
          phone: userList[i].profile && userList[i].profile.phone,
          gender: userList[i].profile && userList[i].profile.gender,
          dob: this.getDateOfBirth(userList[i].profile.dob),
          email: userList[i].email,
          isActive: userList[i].profile && userList[i].profile.is_active,
          dateCreated: this.getDateOfBirth(userList[i].date_created),
          isShowTool: false,
          systemRole: userList[i].group.name,
          systemRoleId: userList[i].group.id,
          isLoading: false,
          points: userList[i].profile && parseInt(userList[i].profile.user_points),
          status: userList[i].profile && userList[i].profile.status,
          type: userList[i].profile && userList[i].profile.type
        }
        this.userList.push(userObj)
      }
      this.isLoading = false
    }
  },
  created () {
    this.getUserList()
  },
  methods: {
    /**
     * Show modal reset password
     */
    onShowModalResetPassword: function (user) {
      this.userForgotPassSelected = user
      this.confirmModalTitle = this.$t('forgotPassword.confirmResetPassword')
      this.modalConfirmTypeSelected = this.modalConfirmType.FORGOT_PASSWORD_USER
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Get system role
     */
    getSystemRole: function (roles) {
      let roleStr = ''
      for (let i = 0, size = roles.length; i < size; i++) {
        roleStr += roles[i].name + (i + 1 < size ? ', ' : '')
      }
      return roleStr
    },
    /**
     * Hover email
     */
    onHoverEmail: function (userItem) {
      userItem.isShowTool = true
    },
    /**
     * Leave email
     */
    onLeaveEmail: function (userItem) {
      userItem.isShowTool = false
    },
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.hasSystemRole(RoleType.ADMIN, this.GET_CURRENT_ACCOUNT)
    },
    isModerator: function () {
      return functionUtils.hasSystemRole(RoleType.MODERATOR, this.GET_CURRENT_ACCOUNT)
    },
    // Check Accountant
    isAccountant: function () {
      return functionUtils.hasSystemRole(RoleType.ACCOUNTANT, this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Show modal view detail user
     */
    onShowModalViewDetailUser: function (item) {
      this.$refs.modalAddUser.onShowModalUpdateUser(this.userPaginate.currentPage, false, item)
    },
    /**
     * Search
     */
    onSearch: function (event) {
      event.preventDefault()
      this.getUserList()
    },
    /**
     * Has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    /**
     * Show modal add user
     */
    onShowModalAddUser: function () {
      this.$refs.modalAddUser.onShowModal(this.userPaginate.currentPage, false)
    },
    /**
     * Show modal delete user
     */
    onShowModalDeleteUser: function (userId) {
      this.userSelected.id = userId
      this.confirmModalTitle = this.$t('user.confirmDeleteUserTitle')
      this.modalConfirmTypeSelected = this.modalConfirmType.DELETE_USER
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Delete user
     */
    onDeleteUser: function () {
      let filter = {
        params: {
          entityId: sessionStorage.getItem('entityId')
        }
      }
      this.DELETE_USER({ id: this.userSelected.id, ...filter }).then(
        function () {
          this.userSelected.id = null
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.getUserList()
        }.bind(this)
      )
    },
    /**
     * Reset password
     */
    onResetPassword: function () {
      let filter = {
        email: this.userForgotPassSelected.email,
        // fromDomainType: this.userForgotPassSelected.numberOfEntity !== 0 ? LoginFromType.ADMIN : LoginFromType.CUSTOMER,
        domainWithBasePath: this.userForgotPassSelected.numberOfEntity !== 0 ? stringUtils.DOMAIN_BASE_PATH_ADMIN : stringUtils.CUSTOMER_URL
      }
      this.userForgotPassSelected.isLoading = true
      this.SEND_EMAIL_FORGOT_PASSWORD(filter).then(
        function () {
          this.userForgotPassSelected.isLoading = false
          this.ON_SHOW_TOAST({
            'message': this.$t('forgotPassword.pleaseCheckEmailToChangePassword'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      ).catch(
        function () {
          this.userForgotPassSelected.isLoading = false
          this.ON_SHOW_TOAST({
            'message': this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    /**
     * Get date of birth
     */
    getDateOfBirth: function (dob) {
      if (!functionUtils.isNull(dob)) {
        return dateUtils.formatDate(dob, dateUtils.STATIC_FORMAT_DATE_TIME_ZONE, dateUtils.STATIC_FORMAT_DATE)
      }
      return null
    },
    /**
     * Get gender
     */
    getGender: function (gender) {
      if (gender === GenderType.MALE) {
        return 'userProfile.genderType.male'
      } else if (gender === GenderType.FEMALE) {
        return 'userProfile.genderType.female'
      } else {
        return 'userProfile.genderType.other'
      }
    },
    /**
     * Get user list
     */
    getUserList: function () {
      this.isLoading = true
      let filter = {
        params: {
          page: this.userPaginate.currentPage,
          searchText: this.userSearch.searchText,
          inGroup: [sysGroupEnum.CUSTOMER]
        }
      }
      this.GET_USER_LIST(filter).then(
        function () {}
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.userPaginate.currentPage = page
      this.getUserList()
    },
    onShowModalChargePoints: function (userItem) {
      this.$refs.ChargePointsModal.onShowModal(userItem, this.userPaginate.currentPage)
    },
    getUserListAfterChargePoints: function () {
      this.getUserList()
    },
    showModalUpdateCustomer: function (customerId, status) {
      this.confirmLockCustomerModalTitle = status === this.customerStatus.LOCK ? this.$t('users.lockCustomerTitle') : this.$t('users.unlockCustomerTitle')
      this.customerSelect = {
        id: customerId,
        status: status
      }
      this.$refs.confirmLockCustomerModal.onShowModal()
    },
    // lock and unlock function
    updateCustomerStatus: function () {
      this.isLoading = true
      this.UPDATE_PREPAID_CARD(this.customerSelect).then(
        function () {
          this.isLoading = false
          this.getUserList()
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    // refund points
    showModalRefundPoints: function (userItem) {
      this.$refs.refundPointsModal.onShowModal(userItem, this.userPaginate.currentPage)
    },
    onShowTransactionHistory: function (userId) {
      //
      this.$refs.transactionHistoryListForUser.onShowModal(userId, false)
    },
    onShowMoveToStaff: function (userData) {
      this.$refs.moveCustomerToStaff.onShowModal(userData, this.userPaginate.currentPage)
    },
    ...mapActions(['GET_USER_LIST', 'UPDATE_USER_PROFILE', 'DELETE_USER', 'ON_SHOW_TOAST', 'ACTIVE_USER', 'SEND_EMAIL_FORGOT_PASSWORD', 'UPDATE_PREPAID_CARD'])
  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/material-dashboard/_colors.scss";
/deep/ .test-primary {
  background-color: #c7c7c7c7 !important;
}
/deep/ .test-primary:hover {
  background-color: #c7c7c7c7 !important;
}
/deep/ .theme--light.v-list {
  background-color: transparent;
}
.border-style {
  border-bottom: 2px solid $border-color;
}
.cursor-pointer {
  cursor: pointer !important;
}
.hover-detail-text:hover {
  border-bottom: 1px solid $border-color;
}
.hover-delete-text:hover {
  border-bottom: 1px solid $border-color;
}
.hover-active-text:hover {
  border-bottom: 1px solid $border-color;
}
.v-menu__content {
  background: #fff;
}
</style>
